import React, { useEffect } from "react";
import "../Styles/country.css";
import Footerz from "./footer";

const Country = () =>
{
  useEffect(() =>
  {
    window.scrollTo(0, 0);

  }, [])
  return (
    <>
      <div className="cmain">
        <div style={{ marginTop: "10%" }} className="ctop">
          <h1>
            Asia Jets is currently conducting extensive market study in the ASEAN
            region to decide on the 1<sup>st</sup> country for launch <br />
          </h1>
          <br />
          <h1 className="respo-h">Why ASEAN?</h1>
          <p className="respos-p">
            The ASEAN with a combined GDP of ~ US $3.7 trillion with over 670
            million people in population size is forecast to be the world’s
            fourth-largest economy by 2030. <br /> <br /> Almost every country in the ASEAN is
            showing robust economic growth. <br /> <br />
            With such progress comes a need for increased efficiency in every aspect of a country’s growth. Particularly when it comes to the effective utilisation of every minute of  Business & Political leaders time.
          </p>
          <div className="cimg">
            <img src="/asian.png" alt="member" />
          </div>
          <p className="respo-p">The Association of Southeast Asian Nations, or ASEAN, was established on 8 August 1967 in Bangkok, Thailand, with the signing of the ASEAN Declaration (Bangkok Declaration) by the Founding Fathers of ASEAN: <br /> Indonesia, Malaysia, Philippines, Singapore and Thailand. <br /> <br />Brunei Darussalam joined ASEAN on 7 January 1984, followed by Vietnam on 28 July 1995, Lao PDR and Myanmar on 23 July 1997, and Cambodia on 30 April 1999, making up what is today  <br />the ten Member States of ASEAN </p>

        </div>
        <Footerz />
      </div>
      <p className="last">© 2024 Asia Jets</p>
    </>

  );
};

export default Country;
